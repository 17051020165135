import {
  Box,
  Button,
  Divider,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material'
import React, { useState } from 'react'

import { AxiosError } from 'axios'
import { Dashboard } from 'components/common/Dashboard/Dashboard'
import FileUpload from 'react-material-file-upload'
import { serverAxios } from 'utils/api'
import { useParams } from 'react-router-dom'
import { useTenantFeatures } from 'features/tenantFeatures/redux/hooks'

export const ModelEditLayout: React.FC = () => {
  const { tenantId } = useParams()
  const { tenants } = useTenantFeatures()
  const tenant = React.useMemo(
    () =>
      tenantId
        ? tenants.find((tenantItem) => tenantItem.id === tenantId) || null
        : null,
    [tenantId, tenants],
  )

  const [uiDisabledCounter, setUiDisableCounter] = useState(0)
  const [xlsxFileState, setXlsxFileState] = useState<File[]>([])
  const [pyFileState, setPyFileState] = useState<File[]>([])
  const [versionName, setVersionName] = React.useState('')
  const [versionId, setVersionId] = React.useState('')

  const canUpload = () =>
    // xlsxFileState.length === 1 && versionName && !uiDisabledCounter
    xlsxFileState.length === 1 &&
    (versionName || versionId) &&
    !uiDisabledCounter

  const handleUpload = async () => {
    const fd = new FormData()
    if (pyFileState.length === 1) {
      fd.append('files', pyFileState[0])
    }
    fd.append('files', xlsxFileState[0])
    try {
      setUiDisableCounter(1)
      const uploadModel = await serverAxios.post(
        '/admin/sheets_pipeline/version_to_db',
        fd,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: '/admin/sheets_pipeline/version_to_db',
          params: {
            tenant: tenantId,
            version_id: versionId,
            version_name: versionName,
          },
        },
      )
    } catch (err: unknown) {
      if (err instanceof AxiosError) {
        alert(
          err?.response?.data?.Stack ||
            err?.response?.data?.Error ||
            err?.response?.data?.Message,
        )
      }
    } finally {
      setUiDisableCounter(0)
    }
  }

  return (
    <Dashboard
      title={
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <span>
            Edit Model: <b>{tenant?.label || tenant?.id || ''}</b>
          </span>
        </Box>
      }
    >
      <Box display="flex">
        <Box mr={4} width="25%">
          <Typography style={{ marginBottom: '5px' }}>
            Upload XLSX file
          </Typography>
          <FileUpload value={xlsxFileState} onChange={setXlsxFileState} />
          <Typography style={{ marginTop: '5px' }}>
            In case of any errors try:
            <br />
            - Close excel app.
            <br />
            - Close other tenant management tabs.
            <br />- If it’s still not working try to change excel file name or
            folder.
          </Typography>
        </Box>
        <Box width="25%">
          <Typography style={{ marginBottom: '5px' }}>
            Upload Py Model file
          </Typography>
          <FileUpload value={pyFileState} onChange={setPyFileState} />
        </Box>
      </Box>
      {uiDisabledCounter === 1 ? (
        <LinearProgress style={{ marginBottom: '20px', marginTop: '10px' }} />
      ) : (
        <Divider style={{ marginBottom: '20px', marginTop: '20px' }} />
      )}
      <Box display="flex">
        <TextField
          id="outlined-controlled"
          label="Version Name"
          style={{ maxHeight: '150px', marginRight: '20px' }}
          value={versionName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setVersionName(event.target.value)
          }}
        />
        <TextField
          id="outlined-controlled"
          label="Version Id"
          style={{ maxHeight: '150px', marginRight: '20px' }}
          value={versionId}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setVersionId(event.target.value)
          }}
        />
        <Button
          variant="contained"
          disabled={!canUpload()}
          type="submit"
          onClick={handleUpload}
          style={{ marginRight: '20px' }}
        >
          Upload Version
        </Button>
      </Box>
    </Dashboard>
  )
}
