/* eslint-disable no-param-reassign */
import type { Maybe, TenantConfiguration } from 'types'
import type { ErrorActionPayload } from 'store/alerts/alertsMiddleware'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type TenantConfigState = {
  config: Maybe<Partial<TenantConfiguration>>
  isLoading: boolean
  error: string
}

const initialState: TenantConfigState = {
  config: null,
  isLoading: false,
  error: '',
}

export type FetchDefaultConfig = PayloadAction<{
  configKey: string
}>

export type FetchTenantConfig = PayloadAction<{
  tenantId: string
  configKey: string
}>

export type UpdateDefaultConfig = PayloadAction<{
  value: string
  configKey: string
}>

export type UpdateTenantConfig = PayloadAction<{
  value: string
  tenantId: string
  configKey: string
}>

export type FetchTenantSQLConfig = PayloadAction<{
  tenantId: string
  viewName: string
}>

export const tenantConfigSlice = createSlice({
  name: 'configureTenant',
  initialState,
  reducers: {
    fetchDefaultConfig(state, action: FetchDefaultConfig) {
      state.isLoading = true
      state.config = null
    },
    fetchDefaultConfigSuccess(
      state,
      action: PayloadAction<TenantConfigState['config']>,
    ) {
      state.isLoading = false
      state.error = ''
      state.config = action.payload
    },
    fetchDefaultConfigError(state, action: ErrorActionPayload) {
      state.isLoading = false
      state.error = action.payload.message
    },

    fetchTenantConfig(state, action: FetchTenantConfig) {
      state.isLoading = true
      state.config = null
    },
    fetchTenantConfigSuccess(
      state,
      action: PayloadAction<TenantConfigState['config']>,
    ) {
      state.isLoading = false
      state.error = ''
      state.config = action.payload
    },
    fetchTenantConfigError(state, action: ErrorActionPayload) {
      state.isLoading = false
      state.error = action.payload.message
    },

    updateDefaultConfig(state, action: UpdateDefaultConfig) {
      state.isLoading = true
    },
    updateDefaultConfigSuccess(
      state,
      action: PayloadAction<TenantConfigState['config']>,
    ) {
      state.isLoading = false
      state.error = ''
      state.config = action.payload
    },
    updateDefaultConfigError(state, action: ErrorActionPayload) {
      state.isLoading = false
      state.error = action.payload.message
    },

    updateTenantConfig(state, action: UpdateTenantConfig) {
      state.isLoading = true
    },
    updateTenantConfigSuccess(
      state,
      action: PayloadAction<TenantConfigState['config']>,
    ) {
      state.isLoading = false
      state.error = ''
      state.config = action.payload
    },
    updateTenantConfigError(state, action: ErrorActionPayload) {
      state.isLoading = false
      state.error = action.payload.message
    },

    fetchTenantSQLConfig(state, action: FetchTenantSQLConfig) {
      state.isLoading = true
      state.config = null
    },
    fetchTenantSQLConfigSuccess(
      state,
      action: PayloadAction<TenantConfigState['config']>,
    ) {
      state.isLoading = false
      state.error = ''
      state.config = action.payload
    },
    fetchTenantSQLConfigError(state, action: ErrorActionPayload) {
      state.isLoading = false
      state.error = action.payload.message
    },

    updateTenantSQLConfig(state, action: UpdateTenantConfig) {
      state.isLoading = true
    },
    updateTenantSQLConfigSuccess(
      state,
      action: PayloadAction<TenantConfigState['config']>,
    ) {
      state.isLoading = false
      state.error = ''
      state.config = action.payload
    },
    updateTenantSQLConfigError(state, action: ErrorActionPayload) {
      state.isLoading = false
      state.error = action.payload.message
    },
  },
})

export const tenantConfigReducer = tenantConfigSlice.reducer
