import React from 'react'
import styled from 'styled-components'

const $FeatureFlagTitle = styled.span`
  font-family: monospace;
`

export const FeatureFlagTitle: React.FC = ({ children }) => (
  <$FeatureFlagTitle>{children}</$FeatureFlagTitle>
)
