/* eslint-disable import/no-cycle */
import type {
  FetchDefaultConfig,
  FetchTenantConfig,
  FetchTenantSQLConfig,
  UpdateDefaultConfig,
  UpdateTenantConfig,
} from './reducers'
import { call, put, takeLatest } from 'redux-saga/effects'
import type { SagaIterator } from 'redux-saga'
import type { TenantConfiguration } from 'types'
import { extractErrorMessage } from 'utils/errors'
import { serverAxios } from 'utils/api'
import { tenantConfigSlice } from './reducers'

function* fetchDefaultConfig(action: FetchDefaultConfig): SagaIterator {
  const { configKey } = action.payload
  try {
    const response: { value: Partial<TenantConfiguration> } = yield call(
      serverAxios.get,
      `/admin/default_raw_configs/${configKey}/`,
    )
    yield put(
      tenantConfigSlice.actions.fetchDefaultConfigSuccess(response.value),
    )
  } catch (e) {
    const error = extractErrorMessage(e as Error)
    yield put(
      tenantConfigSlice.actions.fetchDefaultConfigError({ message: error }),
    )
  }
}

function* fetchTenantConfig(action: FetchTenantConfig): SagaIterator {
  const { tenantId, configKey } = action.payload
  try {
    const response: { value: Partial<TenantConfiguration> } = yield call(
      serverAxios.get,
      `/admin/tenants/${tenantId}/raw_configs/${configKey}/`,
    )
    yield put(
      tenantConfigSlice.actions.fetchTenantConfigSuccess(response.value),
    )
  } catch (e) {
    const error = extractErrorMessage(e as Error)
    yield put(
      tenantConfigSlice.actions.fetchTenantConfigError({ message: error }),
    )
  }
}

function* updateDefaultConfig(action: UpdateDefaultConfig): SagaIterator {
  const { value, configKey } = action.payload
  try {
    const response: { value: Partial<TenantConfiguration> } = yield call(
      serverAxios.put,
      `/admin/default_raw_configs/${configKey}/`,
      { value: JSON.parse(value) },
    )
    yield put(
      tenantConfigSlice.actions.updateDefaultConfigSuccess(response.value),
    )
  } catch (e) {
    const error = extractErrorMessage(e as Error)
    yield put(
      tenantConfigSlice.actions.updateDefaultConfigError({ message: error }),
    )
  }
}

function* updateTenantConfig(action: UpdateTenantConfig): SagaIterator {
  const { value, tenantId, configKey } = action.payload
  try {
    const response: { value: Partial<TenantConfiguration> } = yield call(
      serverAxios.put,
      `/admin/tenants/${tenantId}/raw_configs/${configKey}/`,
      { value: JSON.parse(value) },
    )
    yield put(
      tenantConfigSlice.actions.updateTenantConfigSuccess(response.value),
    )
  } catch (e) {
    const error = extractErrorMessage(e as Error)
    yield put(
      tenantConfigSlice.actions.updateTenantConfigError({ message: error }),
    )
  }
}

function* fetchTenantSQLConfig(action: FetchTenantSQLConfig): SagaIterator {
  const { tenantId, viewName } = action.payload
  try {
    const response: { body: Partial<TenantConfiguration> } = yield call(
      serverAxios.get,
      `/admin/tenants/${tenantId}/pg_views/${viewName}/`,
    )
    yield put(
      tenantConfigSlice.actions.fetchTenantSQLConfigSuccess(response.body),
    )
  } catch (e) {
    const error = extractErrorMessage(e as Error)
    yield put(
      tenantConfigSlice.actions.fetchTenantSQLConfigError({ message: error }),
    )
  }
}

function* updateTenantSQLConfig(action: UpdateTenantConfig): SagaIterator {
  const { value, tenantId, configKey } = action.payload
  try {
    const response: { body: Partial<TenantConfiguration> } = yield call(
      serverAxios.put,
      `/admin/tenants/${tenantId}/pg_views/${configKey}/`,
      { body: value },
    )
    yield put(
      tenantConfigSlice.actions.updateTenantSQLConfigSuccess(response.body),
    )
  } catch (e) {
    const error = extractErrorMessage(e as Error)
    yield put(
      tenantConfigSlice.actions.updateTenantSQLConfigError({ message: error }),
    )
  }
}

export default [
  takeLatest(tenantConfigSlice.actions.fetchDefaultConfig, fetchDefaultConfig),
  takeLatest(tenantConfigSlice.actions.fetchTenantConfig, fetchTenantConfig),
  takeLatest(tenantConfigSlice.actions.updateTenantConfig, updateTenantConfig),
  takeLatest(
    tenantConfigSlice.actions.updateDefaultConfig,
    updateDefaultConfig,
  ),
  takeLatest(
    tenantConfigSlice.actions.fetchTenantSQLConfig,
    fetchTenantSQLConfig,
  ),
  takeLatest(
    tenantConfigSlice.actions.updateTenantSQLConfig,
    updateTenantSQLConfig,
  ),
]
