/* eslint-disable import/no-cycle */
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import { alertsMiddleware } from './alerts/alertsMiddleware'
import { alertsReducer } from './alerts/reducers'
import { authReducer } from 'features/auth/redux/reducers'
import createSagaMiddleware from 'redux-saga'
import { rootSaga } from './sagas'
import { tenantConfigReducer } from 'features/tenantConfig/redux/reducers'
import { tenantsReducer } from 'features/tenantFeatures/redux/reducers'
import { userReducer } from 'features/example/redux/reducers'

const isDev = process.env.NODE_ENV === 'development'

const rootReducer = combineReducers({
  users: userReducer,
  auth: authReducer,
  tenantConfig: tenantConfigReducer,
  tenantFeatures: tenantsReducer,
  alerts: alertsReducer,
})

export const createStore = () => {
  const sagaMiddleware = createSagaMiddleware()
  const middleware = [sagaMiddleware, alertsMiddleware]

  const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: isDev,
  })

  sagaMiddleware.run(rootSaga)

  return store
}

export const store = createStore()

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof createStore>
export type AppDispatch = AppStore['dispatch']
