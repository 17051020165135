/* eslint-disable import/no-cycle */
import Box from '@mui/material/Box'
import { CodeEditor } from 'components/common/CodeEditor/CodeEditor'
import type { ConfigField } from 'types'
import React from 'react'
import { useTenantConfig } from 'features/tenantConfig/redux/hooks'

export const SQLField: React.FC<{ field: ConfigField }> = ({ field }) => {
  const { config } = useTenantConfig()
  const defaultValue = React.useMemo(
    () =>
      typeof config === 'object' && config !== null
        ? JSON.stringify(config)
        : config || '',
    [config],
  )

  return (
    <Box paddingRight={1} paddingBottom={1}>
      <CodeEditor field={field} lang="SQL" defaultValue={defaultValue} />
    </Box>
  )
}
