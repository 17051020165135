import { DefaultFeaturesTable } from 'components/common/DefaultFeaturesTable/DefaultFeaturesTable'
import { EditFeaturesModal } from 'components/common/EditFeaturesModal/EditFeaturesModal'
import type { EditFeaturesModalValues } from 'components/common/EditFeaturesModal/EditFeaturesModal'
import React from 'react'
import { useTenantFeatures } from '../redux/hooks'

export const DefaultTenantFeaturesContainer: React.FC = () => {
  const {
    defaultFeatures,
    editFeatureHandler,
    updateDefaultFeature,
    editFeature,
  } = useTenantFeatures()
  const [open, setOpen] = React.useState(false)

  const onEditHandler = React.useCallback(
    (feature) => {
      setOpen(true)
      editFeatureHandler(feature)
    },
    [editFeatureHandler],
  )

  const openClose = React.useCallback(() => {
    setOpen(false)
    editFeatureHandler(null)
  }, [editFeatureHandler])

  const onSubmit = React.useCallback(
    (values: EditFeaturesModalValues) => {
      updateDefaultFeature({
        ...values,
        feature_key: editFeature?.key as string,
      })
    },
    [editFeature?.key, updateDefaultFeature],
  )

  return (
    <div>
      <DefaultFeaturesTable
        defaultFeatures={defaultFeatures}
        openToggle={onEditHandler}
      />
      {editFeature && (
        <EditFeaturesModal
          open={open}
          onClose={openClose}
          onSubmit={onSubmit}
        />
      )}
    </div>
  )
}
