import type { AxiosError } from 'axios'

import type { ServerErrorResponse } from './api'

export const isInternalError = (error: Error): boolean =>
  [
    EvalError,
    RangeError,
    ReferenceError,
    SyntaxError,
    TypeError,
    URIError,
  ].some((item) => error instanceof item)

export const isAxiosError = <T extends ServerErrorResponse>(
  error: Error,
): error is AxiosError<T> => (error as AxiosError<T>).isAxiosError

export const extractErrorMessage = <R>(
  error: Error | AxiosError<R>,
  inputDefaultMessage?: string,
): string => {
  const defaultMessage = inputDefaultMessage || 'Something went wrong'

  if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line
    console.error(error)
  }

  if (isInternalError(error)) {
    // TODO: log internal error to sentry etc.
    return defaultMessage
  }

  if (isAxiosError(error)) {
    return error?.response?.data?.message || error.message || defaultMessage
  }

  return error.message || defaultMessage
}
