import type { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import axios from 'axios'
import { environment } from 'environment'
import { selectToken } from 'features/auth/redux/selectors'
// eslint-disable-next-line import/no-cycle
import { store } from 'store'

export type ServerSuccessResponse = unknown

export interface ServerErrorResponse {
  message: string
}

export const serverAxios = axios.create({
  timeout: 30000,
  baseURL: `${environment.REACT_APP_API_URL}/api/`,
})

serverAxios.interceptors.request.use(
  (requestConfig: AxiosRequestConfig): AxiosRequestConfig => {
    const state = store.getState()
    const accessToken = selectToken(state)
    if (accessToken) {
      // eslint-disable-next-line no-param-reassign, @typescript-eslint/no-non-null-assertion
      requestConfig.headers!.authorization = `Bearer ${accessToken}`
    }

    return requestConfig
  },
)

serverAxios.interceptors.response.use(
  (response: AxiosResponse<ServerSuccessResponse>) => response.data,
  (error: AxiosError<ServerErrorResponse>) => Promise.reject(error),
)
