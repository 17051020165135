import { Alert, AlertTitle, Box, Button } from '@mui/material'

import React from 'react'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import { useAuthorization } from 'features/auth/hooks/useAuthorization'

const AUTO_LOGIN_URL_TOKEN = 'auto-login'
const ACCES_DENIED_URL_TOKEN = 'access-denied'

const isAccessDenied = (error?: Error): boolean =>
  error?.message === 'Access denied'

export const Login: React.FC = () => {
  const { error, logoutHandler, loginHandler } = useAuthorization()
  const [attemptedToLogin, setAttemptedToLogin] = React.useState(false)
  const [loggedOutDueToAccessDenied, setLoggedOutDueToAccessDenied] =
    React.useState(false)

  const handleLogin = React.useCallback(async () => {
    setAttemptedToLogin(true)

    if (isAccessDenied(error)) {
      logoutHandler({
        returnTo: `${window?.location.origin}?${AUTO_LOGIN_URL_TOKEN}`,
      })
      return
    }

    await loginHandler()
  }, [error, loginHandler, logoutHandler])

  React.useEffect(() => {
    if (window.location.search.includes(AUTO_LOGIN_URL_TOKEN)) {
      handleLogin()
      window?.history.replaceState(
        null,
        document.title,
        document.location.origin + document.location.pathname,
      )
    }
  }, [handleLogin])

  React.useEffect(() => {
    if (!attemptedToLogin && isAccessDenied(error)) {
      logoutHandler({
        returnTo: `${window?.location.origin}?${ACCES_DENIED_URL_TOKEN}`,
      })
    }
  }, [attemptedToLogin, error, logoutHandler])

  React.useEffect(() => {
    if (window.location.search.includes(ACCES_DENIED_URL_TOKEN)) {
      setLoggedOutDueToAccessDenied(true)
    }
  }, [setLoggedOutDueToAccessDenied])

  return (
    <Box mt={3}>
      <Alert severity="info" icon={<SecurityRoundedIcon fontSize="inherit" />}>
        <AlertTitle>Welcome to Fintastic Tenant Management</AlertTitle>
        Please login into your account
      </Alert>
      <Box mt={2}>
        {error && (
          <Box mb={1}>
            <Alert severity="error">
              <b>{error.name}</b> {error.message}
            </Alert>
          </Box>
        )}
        {loggedOutDueToAccessDenied && !error && (
          <Box mb={1}>
            <Alert severity="error">
              <b>Error</b> Access denied. Login with another account.
            </Alert>
          </Box>
        )}
        <Button
          color="primary"
          variant="contained"
          disableElevation
          fullWidth
          onClick={handleLogin}
        >
          Proceed to Login
        </Button>
      </Box>
    </Box>
  )
}
