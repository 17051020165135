const requiredVariables = [
  'REACT_APP_API_URL',
  'REACT_APP_AUTH0_AUDIENCE',
  'REACT_APP_AUTH0_DOMAIN',
  'REACT_APP_AUTH0_CLIENT_ID',
  'REACT_APP_CURRENT_ENV_LABEL',
] as const

const optionalVariables = ['REACT_APP_TENANT_KITCHEN_LOGIN'] as const

export const environment = Object.fromEntries(
  [...optionalVariables, ...requiredVariables].map((variableName) => {
    if (
      requiredVariables.includes(
        variableName as typeof requiredVariables[number],
      ) &&
      !process.env[variableName]
    ) {
      throw new Error(`${variableName} is required but not defined`)
    }

    return [variableName, process.env[variableName]]
  }),
) as Record<
  typeof requiredVariables[number] | typeof optionalVariables[number],
  string
>
