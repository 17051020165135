import { LogoutOptions, useAuth0 } from '@auth0/auth0-react'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import React from 'react'
import { authSlice } from '../redux/reducers'
import { environment } from 'environment'
import { selectToken } from '../redux/selectors'

type UseAuthorization = {
  isAuthenticated: boolean
  token: string
  storeTokenHandler: () => Promise<void>
  isLoading: boolean
  loginHandler: () => void
  logoutHandler: (options?: LogoutOptions | undefined) => void
  error?: Error
}

export const useAuthorization = (): UseAuthorization => {
  const {
    isAuthenticated,
    isLoading,
    loginWithPopup,
    logout,
    getAccessTokenSilently,
    error,
  } = useAuth0()

  const dispatch = useAppDispatch()
  const token = useAppSelector(selectToken)

  const loginHandler = React.useCallback(() => {
    loginWithPopup()
  }, [loginWithPopup])

  const logoutHandler = React.useCallback(
    (options) => {
      dispatch(authSlice.actions.logout())
      logout(options)
    },
    [dispatch, logout],
  )

  const storeTokenHandler = React.useCallback(async () => {
    let accessToken

    try {
      accessToken = await getAccessTokenSilently({
        audience: environment.REACT_APP_AUTH0_AUDIENCE,
      })

      if (accessToken) {
        dispatch(authSlice.actions.storeToken(accessToken))
      }
    } catch (e) {
      console.log(e)
    }
  }, [dispatch, getAccessTokenSilently])

  return {
    isAuthenticated,
    token,
    storeTokenHandler,
    isLoading,
    loginHandler,
    logoutHandler,
    error,
  }
}
