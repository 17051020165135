import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'

import { FeatureFlagTitle } from 'components/common/FeatureFlagTitle/FeatureFlagTitle'
import React from 'react'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'
import { theme } from 'utils/theme'
import { useTenantFeatures } from 'features/tenantFeatures/redux/hooks'

const $Box = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 650px;
  background: ${theme.palette.background.paper};
  border: 2px solid #000;
  padding: ${theme.spacing(2, 4)};
`

type EditFeaturesModalProps = {
  open: boolean
  onClose: () => void
  onSubmit: (values: EditFeaturesModalValues) => void
}

export type EditFeaturesModalValues = {
  value: string
  is_enabled: boolean
}

export const EditFeaturesModal: React.FC<EditFeaturesModalProps> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { editFeature } = useTenantFeatures()
  const { handleSubmit, control } = useForm<EditFeaturesModalValues>({
    defaultValues: {
      value: editFeature?.value,
      is_enabled: editFeature?.is_enabled,
    },
  })

  const submitHandler = React.useCallback(
    (values: EditFeaturesModalValues) => {
      onSubmit(values)
    },
    [onSubmit],
  )

  return (
    <Modal open={open} onClose={onClose}>
      <$Box>
        <Typography id="modal-modal-title" variant="h6" component="h3">
          {editFeature?.tenantId ? (
            <span>
              Tenant: <b>{editFeature?.tenantId}</b>
            </span>
          ) : (
            <b>Default feature</b>
          )}
          <br />
          <FeatureFlagTitle>{editFeature?.key}</FeatureFlagTitle>
        </Typography>
        <Box bgcolor="white" borderRadius="4px" mt={4}>
          <Controller
            name="is_enabled"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  value={field.value}
                  onChange={field.onChange}
                >
                  <MenuItem value="true">Enabled</MenuItem>
                  <MenuItem value="false">Disabled</MenuItem>
                </Select>
              </FormControl>
            )}
          />

          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <FormControl fullWidth sx={{ mt: 5 }}>
                <TextField {...field} placeholder="Value" />
              </FormControl>
            )}
          />
          <Button
            variant="contained"
            size="large"
            onClick={handleSubmit(submitHandler)}
            sx={{ mt: 5, width: '140px' }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={onClose}
            sx={{ mt: 5, ml: 5 }}
          >
            Close
          </Button>
        </Box>
      </$Box>
    </Modal>
  )
}
