import { AuthLayout } from 'layouts/AuthLayout'
import React from 'react'
import type { RouteProps } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useAuthorization } from 'features/auth/hooks/useAuthorization'

export const ProtectedRoute: React.FC = ({ children }: RouteProps) => {
  const { isAuthenticated, isLoading } = useAuth0()
  const { token } = useAuthorization()

  if (!isAuthenticated || !token) {
    return <AuthLayout isLoading={isLoading} />
  }

  return <div>{children}</div>
}
