import { Alert, AlertTitle } from '@mui/material'

import React from 'react'
import WarningIcon from '@mui/icons-material/Warning'

export const NotFoundLayout: React.FC = () => (
  <Alert severity="error" icon={<WarningIcon fontSize="inherit" />}>
    <AlertTitle>404 | Page not found</AlertTitle>
  </Alert>
)
