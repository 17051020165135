/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type AlertType = {
  message: string
  severity: 'error' | 'info' | 'success' | 'warning' | 'pending'
  color?: string
}

export type AlertsState = {
  alerts: AlertType[]
}

export type PopupAlertAction = PayloadAction<AlertType>

const initialState: AlertsState = {
  alerts: [],
}

export const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    popupAlert(state, action: PopupAlertAction) {
      state.alerts.push(action.payload)
    },
    removeAlert(state) {
      state.alerts.shift()
    },
  },
})

export const alertsReducer = alertsSlice.reducer
