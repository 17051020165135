/* eslint-disable camelcase */
import type { Tenant, TenantFeature } from 'types'
import type {
  UpdateDefaultFeatureAction,
  UpdateTenantFeatureAction,
} from './reducers'
import { call, put, takeLatest } from 'redux-saga/effects'

import type { SagaIterator } from 'redux-saga'
import { extractErrorMessage } from 'utils/errors'
// eslint-disable-next-line import/no-cycle
import { serverAxios } from 'utils/api'
import { tenantsSlice } from './reducers'

function* fetchTenants(): SagaIterator {
  try {
    const defaultFeatures: { result: TenantFeature[] } = yield call(
      serverAxios.get,
      'admin/default_features/',
    )
    const response: { result: Tenant[] } = yield call(
      serverAxios.get,
      'admin/tenants/',
    )
    yield put(
      tenantsSlice.actions.fetchTenantsSuccess({
        defaultFeatures: defaultFeatures.result,
        tenants: response.result,
      }),
    )
  } catch (e) {
    const error = extractErrorMessage(e as Error)
    yield put(tenantsSlice.actions.fetchTenantsError({ message: error }))
  }
}

function* updateDefaultFeature(
  action: UpdateDefaultFeatureAction,
): SagaIterator {
  const { feature_key, value, is_enabled } = action.payload
  try {
    const response: TenantFeature = yield call(
      serverAxios.put,
      `admin/default_features/${feature_key ? `${feature_key}/` : ''}`,
      {
        value,
        is_enabled,
      },
    )
    yield put(tenantsSlice.actions.updateDefaultFeatureSuccess(response))
  } catch (e) {
    const error = extractErrorMessage(e as Error)
    yield put(
      tenantsSlice.actions.updateDefaultFeatureError({ message: error }),
    )
  }
}

function* updateTenantFeature(action: UpdateTenantFeatureAction): SagaIterator {
  const { feature_key, value, is_enabled, tenant_id } = action.payload
  try {
    const response: TenantFeature = yield call(
      serverAxios.put,
      `admin/tenants/${tenant_id}/features/${
        feature_key ? `${feature_key}/` : ''
      }`,
      {
        value,
        is_enabled,
      },
    )
    const rusult = { ...response, tenant_id }
    yield put(tenantsSlice.actions.updateTenantFeatureSuccess(rusult))
  } catch (e) {
    const error = extractErrorMessage(e as Error)
    yield put(tenantsSlice.actions.updateTenantFeatureError({ message: error }))
  }
}

export default [
  takeLatest(tenantsSlice.actions.fetchTenants, fetchTenants),
  takeLatest(tenantsSlice.actions.updateDefaultFeature, updateDefaultFeature),
  takeLatest(tenantsSlice.actions.updateTenantFeature, updateTenantFeature),
]
