import { Box, Button, Link } from '@mui/material'

import { ConfigureTenantContainer } from 'features/tenantConfig/containers/ConfigureTenantContainer'
import { Dashboard } from 'components/common/Dashboard/Dashboard'
import React from 'react'
import { environment } from 'environment'
import { useParams, NavLink } from 'react-router-dom'
import { useTenantConfig } from 'features/tenantConfig/redux/hooks'
import { useTenantFeatures } from 'features/tenantFeatures/redux/hooks'
import { serverAxios } from 'utils/api'
import { routes } from 'router/routes'

export type CreateConnectionResponse = {
  connection_string: string
}

export const TenantConfigurationLayout: React.FC = () => {
  const { tenantId } = useParams()
  const { tenants } = useTenantFeatures()
  const { isTenantConfig } = useTenantConfig()
  const getConnection = () => {
    serverAxios
      .post(`/admin/postgres/create_user/${tenantId}`)
      .then((response) => {
        const data = response as unknown as CreateConnectionResponse
        alert(data.connection_string)
      })
      .catch((err) => alert('Failed to get connection string'))
  }
  const tenant = React.useMemo(
    () =>
      tenantId
        ? tenants.find((tenantItem) => tenantItem.id === tenantId) || null
        : null,
    [tenantId, tenants],
  )

  const tenantLoginLink = `${
    environment.REACT_APP_TENANT_KITCHEN_LOGIN
  }/?tenant-override=${tenant?.label || tenant?.id}`

  return (
    <Dashboard
      title={
        <Box display="flex" alignItems="center" justifyContent="space-between">
          {isTenantConfig ? (
            <>
              <span>
                Configure Tenant: <b>{tenant?.label || tenant?.id || ''}</b>
              </span>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  href={tenantLoginLink}
                  variant="contained"
                  sx={{ marginLeft: '1.5em' }}
                  target="_blank"
                >
                  Login to tenant
                </Button>
                <Button onClick={getConnection}>Get Connection</Button>
                <Link
                  component={NavLink}
                  to={routes.model_edit(tenantId || '')}
                  variant="button"
                >
                  Edit Model
                </Link>
              </Box>
            </>
          ) : (
            'Configure default config'
          )}
        </Box>
      }
    >
      <ConfigureTenantContainer />
    </Dashboard>
  )
}
