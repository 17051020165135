import { LinearProgress, TableCell, TableHead, TableRow } from '@mui/material'

import { FeatureFlagTitle } from 'components/common/FeatureFlagTitle/FeatureFlagTitle'
import React from 'react'
import styled from 'styled-components'
import { useTenantFeatures } from 'features/tenantFeatures/redux/hooks'

const $TenantCell = styled(TableCell)`
  font-weight: bold;
  left: 0;
  background: white;
  z-index: 3;
  border-right: 3px solid rgba(224, 224, 224, 1);
`

export const Head: React.FC = () => {
  const { config } = useTenantFeatures()

  if (!config?.length) {
    return <LinearProgress />
  }

  return (
    <TableHead>
      <TableRow>
        <$TenantCell component="th" scope="row" key="tenant">
          Tenant
        </$TenantCell>
        {config.map(({ key }) => (
          <TableCell key={key} component="th" scope="row">
            <FeatureFlagTitle>{key}</FeatureFlagTitle>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}
