import { Grid, Paper } from '@mui/material'

import { DefaultFeaturesRow } from './DefaultFeaturesRow'
import React from 'react'
import type { TenantFeature } from 'types'

type DefaultFeaturesTableProps = {
  defaultFeatures: TenantFeature[]
  openToggle: (feature: TenantFeature) => void
}

export const DefaultFeaturesTable: React.FC<DefaultFeaturesTableProps> = ({
  defaultFeatures,
  openToggle,
}) => (
  <Grid container spacing={2} component={Paper}>
    <DefaultFeaturesRow defaultFeatures={defaultFeatures} onEdit={openToggle} />
  </Grid>
)
