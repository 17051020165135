/* eslint-disable import/no-cycle */
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import { FORM_CONFIG } from 'components/common/ConfigurationForm/ConfigurationForm'
import React from 'react'
import type { TenantConfigState } from './reducers'
import { selectConfigTenantState } from './selectors'
import { tenantConfigSlice } from './reducers'
import { useParams } from 'react-router-dom'

type UseTenantConfig = {
  config: TenantConfigState['config']
  isLoading: TenantConfigState['isLoading']
  error: TenantConfigState['error']
  fetchDefaultConfig: () => void
  fetchTenantConfig: () => void
  fetchTenantSQLConfig: () => void
  updateDefaultConfig: (value: string) => void
  updateTenantConfig: (value: string) => void
  updateTenantSQLConfig: (value: string) => void
  isTenantConfig: boolean
  isSQLField: boolean
}

export const CONFIG_URL_KEYS = {
  tenantConfigKey: 'actual',
  defaultConfigKey: 'default',
}

export const useTenantConfig = (): UseTenantConfig => {
  const dispatch = useAppDispatch()
  const { tenantId, configKey, type } = <
    { tenantId: string; configKey: string; type: string }
  >useParams()
  const { config, isLoading, error } = useAppSelector(selectConfigTenantState)
  const isTenantConfig = type === CONFIG_URL_KEYS.tenantConfigKey
  const isSQLField =
    FORM_CONFIG.find((item) => item.name === configKey)?.type === 'SQL'

  const fetchTenantConfig = React.useCallback(() => {
    dispatch(
      tenantConfigSlice.actions.fetchTenantConfig({
        tenantId,
        configKey,
      }),
    )
  }, [configKey, dispatch, tenantId])

  const fetchTenantSQLConfig = React.useCallback(() => {
    dispatch(
      tenantConfigSlice.actions.fetchTenantSQLConfig({
        tenantId,
        viewName: configKey,
      }),
    )
  }, [configKey, dispatch, tenantId])

  const fetchDefaultConfig = React.useCallback(() => {
    dispatch(
      tenantConfigSlice.actions.fetchDefaultConfig({
        configKey,
      }),
    )
  }, [configKey, dispatch])

  const updateDefaultConfig = React.useCallback(
    (value) => {
      dispatch(
        tenantConfigSlice.actions.updateTenantConfig({
          value,
          tenantId,
          configKey,
        }),
      )
    },
    [configKey, dispatch, tenantId],
  )

  const updateTenantConfig = React.useCallback(
    (value) => {
      dispatch(
        tenantConfigSlice.actions.updateDefaultConfig({
          value,
          configKey,
        }),
      )
    },
    [configKey, dispatch],
  )

  const updateTenantSQLConfig = React.useCallback(
    (value) => {
      dispatch(
        tenantConfigSlice.actions.updateTenantSQLConfig({
          value,
          tenantId,
          configKey,
        }),
      )
    },
    [configKey, dispatch, tenantId],
  )

  return {
    config,
    isLoading,
    error,
    fetchDefaultConfig,
    fetchTenantConfig,
    fetchTenantSQLConfig,
    updateDefaultConfig,
    updateTenantConfig,
    updateTenantSQLConfig,
    isTenantConfig,
    isSQLField,
  }
}
