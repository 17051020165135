import 'prismjs'

import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

import { Auth0Provider } from '@auth0/auth0-react'
import { CssBaseline } from '@mui/material'
import React from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { Router } from 'router'
import { environment } from './environment'
import { store } from 'store'
import { theme } from 'utils/theme'

export const App: React.FC = () => (
  <ReduxProvider store={store}>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Auth0Provider
          domain={environment.REACT_APP_AUTH0_DOMAIN}
          clientId={environment.REACT_APP_AUTH0_CLIENT_ID}
          audience={environment.REACT_APP_AUTH0_AUDIENCE}
          redirectUri={window?.location.origin}
        >
          <Router />
        </Auth0Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  </ReduxProvider>
)
