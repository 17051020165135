/* eslint-disable import/no-cycle */
import 'prismjs/themes/prism.css'

import Box from '@mui/material/Box'
import { CodeEditor } from 'components/common/CodeEditor/CodeEditor'
import type { ConfigField } from 'types'
import React from 'react'
import { useTenantConfig } from 'features/tenantConfig/redux/hooks'

export const JSONField: React.FC<{ field: ConfigField }> = ({ field }) => {
  const { config } = useTenantConfig()

  return (
    <Box paddingRight={1} paddingBottom={1}>
      <CodeEditor
        field={field}
        lang="JSON"
        defaultValue={config ? JSON.stringify(config) : ''}
      />
    </Box>
  )
}
