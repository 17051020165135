/* eslint-disable no-param-reassign */
import type { Maybe, Tenant, TenantFeature } from 'types'

import type { ErrorActionPayload } from 'store/alerts/alertsMiddleware'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

function uniqByKeepLast(a: Array<any>, key: CallableFunction) {
  return [...new Map(a.map((x) => [key(x), x])).values()]
}

export type TenantsState = {
  editFeature: Maybe<TenantFeature & { tenantId?: string }>
  defaultFeatures: TenantFeature[]
  tenants: Tenant[]
  isLoading: boolean
  error: string
}

const initialState: TenantsState = {
  editFeature: null,
  defaultFeatures: [],
  tenants: [],
  isLoading: false,
  error: '',
}

export type UpdateDeafaultFeature = {
  value: string
  is_enabled: boolean
  feature_key: string
}

export type UpdateTenantFeature = { tenant_id: string } & UpdateDeafaultFeature

export type UpdateDefaultFeatureAction = PayloadAction<UpdateDeafaultFeature>
export type UpdateTenantFeatureAction = PayloadAction<UpdateTenantFeature>

export const tenantsSlice = createSlice({
  name: 'tenantFeatures',
  initialState,
  reducers: {
    editFeature(
      state,
      action: PayloadAction<{
        feature: Maybe<TenantFeature>
        tenantId?: string
      }>,
    ) {
      state.editFeature = action.payload.feature
        ? {
            ...action.payload.feature,
            ...(action.payload.tenantId && {
              tenantId: action.payload.tenantId,
            }),
          }
        : null
    },

    fetchTenants(state) {
      state.isLoading = true
    },
    fetchTenantsSuccess(
      state,
      action: PayloadAction<{
        defaultFeatures: TenantFeature[]
        tenants: Tenant[]
      }>,
    ) {
      state.isLoading = false
      state.error = ''
      state.tenants = action.payload.tenants
      state.defaultFeatures = action.payload.defaultFeatures
    },
    fetchTenantsError(state, action: ErrorActionPayload) {
      state.isLoading = false
      state.error = action.payload.message
    },

    updateDefaultFeature(state, action: UpdateDefaultFeatureAction) {
      state.isLoading = true
    },
    updateDefaultFeatureSuccess(state, action: PayloadAction<TenantFeature>) {
      state.isLoading = false
      state.editFeature = null

      state.defaultFeatures = uniqByKeepLast(
        [...state.defaultFeatures, action.payload],
        (feature: TenantFeature) => feature.key,
      )
      state.tenants.forEach((tenant, index) => {
        state.tenants[index].features = tenant.features.map((item) => {
          if (item.key === action.payload.key) {
            return action.payload
          }
          return item
        })
      })
    },
    updateDefaultFeatureError(state, action: ErrorActionPayload) {
      state.isLoading = false
      state.error = action.payload.message
    },

    updateTenantFeature(state, action: UpdateTenantFeatureAction) {
      state.isLoading = true
    },
    updateTenantFeatureSuccess(
      state,
      action: PayloadAction<TenantFeature & { tenant_id: string }>,
    ) {
      state.isLoading = false
      state.editFeature = null
      state.tenants.forEach((tenant) => {
        if (tenant.id === action.payload.tenant_id) {
          if (
            tenant.features.find(
              (feature) => feature.key === action.payload.key,
            )
          ) {
            tenant.features = tenant.features.map((item) => {
              if (item.key === action.payload.key) {
                return action.payload
              }
              return item
            })
          } else {
            tenant.features.unshift(action.payload)
          }
        }
      })
    },
    updateTenantFeatureError(state, action: ErrorActionPayload) {
      state.isLoading = false
      state.error = action.payload.message
    },
  },
})

export const tenantsReducer = tenantsSlice.reducer
