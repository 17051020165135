import { createTheme } from '@mui/material/styles'
import { indigo } from '@mui/material/colors'

export const theme = createTheme({
  typography: {
    fontFamily: "'Ubuntu', sans-serif;",
    fontWeightBold: 600,
    h1: {
      margin: '1.5rem 0',
      fontSize: '1.5rem',
      fontWeight: 500,
    },
    button: {
      textTransform: 'none',
      fontWeight: 'bold',
    },
    subtitle2: {
      fontWeight: 400,
    },
  },
  palette: {
    primary: {
      main: '#f3622d',
      dark: '#000',
    },
    secondary: indigo,
    text: {
      primary: '#000',
      secondary: '#adadad',
    },
    background: {
      paper: '#fff',
    },
    error: {
      main: '#d32f2f',
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: indigo[700],
          textDecorationColor: indigo[600],
        },
      },
    },
  },
  spacing: 4,
})
