import { Button } from '@mui/material'
import { EditFeaturesModal } from 'components/common/EditFeaturesModal/EditFeaturesModal'
import type { EditFeaturesModalValues } from 'components/common/EditFeaturesModal/EditFeaturesModal'
import { FeaturesTable } from 'components/common/FeaturesTable/FeaturesTable'
import { NavLink } from 'react-router-dom'
import React from 'react'
import { routes } from 'router/routes'
import { useTenantFeatures } from '../redux/hooks'

export const TenantFeaturesContainer: React.FC = () => {
  const { tenants, editFeatureHandler, updateTenantFeature, editFeature } =
    useTenantFeatures()
  const [open, setOpen] = React.useState(false)

  const onEditHandler = React.useCallback(
    (feature, tenantId: string) => {
      setOpen(true)
      editFeatureHandler(feature, tenantId)
    },
    [editFeatureHandler],
  )

  const openClose = React.useCallback(() => {
    setOpen(false)
    editFeatureHandler(null)
  }, [editFeatureHandler])

  const onSubmit = React.useCallback(
    (values: EditFeaturesModalValues) => {
      updateTenantFeature({
        ...values,
        feature_key: editFeature?.key as string,
        tenant_id: editFeature?.tenantId as string,
      })
    },
    [editFeature?.key, editFeature?.tenantId, updateTenantFeature],
  )

  return (
    <div>
      <FeaturesTable tenants={tenants} openToggle={onEditHandler} />
      {editFeature && (
        <EditFeaturesModal
          open={open}
          onClose={openClose}
          onSubmit={onSubmit}
        />
      )}
    </div>
  )
}
