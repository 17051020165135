/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export type AuthState = {
  token: string
}

const initialState: AuthState = {
  token: '',
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    storeToken(state, action: PayloadAction<string>) {
      state.token = action.payload
    },
    logout(state) {
      state.token = ''
    },
  },
})

export const authReducer = authSlice.reducer
