import { createSelector, createStructuredSelector } from 'reselect'
import type { RootState } from 'store'
import type { Selector } from 'reselect'
import type { TenantConfigState } from './reducers'

const selectAll: Selector<RootState, TenantConfigState> = (s) => s.tenantConfig

export const selectEntities: Selector<RootState, TenantConfigState['config']> =
  createSelector(selectAll, (state) => state.config)

export const selectError: Selector<RootState, TenantConfigState['error']> =
  createSelector(selectAll, (state) => state.error)

export const selectLoading: Selector<
  RootState,
  TenantConfigState['isLoading']
> = createSelector(selectAll, (state) => state.isLoading)

export const selectConfigTenantState = createStructuredSelector<
  RootState,
  TenantConfigState
>({
  config: selectEntities,
  error: selectError,
  isLoading: selectLoading,
})
