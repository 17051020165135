/* eslint-disable import/no-cycle */
import 'prismjs/themes/prism.css'
import 'prismjs/components/prism-javascript'
import 'prismjs/components/prism-sql'
import './styles.css'
import {
  CONFIG_URL_KEYS,
  useTenantConfig,
} from 'features/tenantConfig/redux/hooks'
import { highlight, languages } from 'prismjs'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import type { ConfigField } from 'types'
import Editor from 'react-simple-code-editor'
import React from 'react'
import { routes } from 'router/routes'

export type CodeEditorProps = {
  field: ConfigField
  lang: 'JSON' | 'SQL'
  defaultValue?: string
}

export const CodeEditor: React.FC<CodeEditorProps> = ({
  field,
  lang,
  defaultValue = '',
}) => {
  const navigate = useNavigate()
  const { tenantId } = useParams<{ tenantId: string }>()
  const [value, setValue] = React.useState(defaultValue)
  const {
    isTenantConfig,
    fetchDefaultConfig,
    fetchTenantConfig,
    updateDefaultConfig,
    updateTenantConfig,
    updateTenantSQLConfig,
    isSQLField,
  } = useTenantConfig()

  const handleChange = React.useCallback(
    (nextValue) => setValue(nextValue),
    [setValue],
  )

  const submitHandler = React.useCallback(() => {
    if (isSQLField) {
      updateTenantSQLConfig(value)
      return
    }
    const updateConfig = isTenantConfig
      ? updateDefaultConfig
      : updateTenantConfig
    updateConfig(value)
  }, [
    isSQLField,
    isTenantConfig,
    updateDefaultConfig,
    updateTenantConfig,
    updateTenantSQLConfig,
    value,
  ])

  const getValueByType = React.useCallback(() => {
    navigate(
      routes.tenant_configuration(
        tenantId as string,
        field.name,
        isTenantConfig
          ? CONFIG_URL_KEYS.defaultConfigKey
          : CONFIG_URL_KEYS.tenantConfigKey,
      ),
    )
    const fetchConfig = isTenantConfig ? fetchDefaultConfig : fetchTenantConfig
    fetchConfig()
  }, [
    fetchDefaultConfig,
    fetchTenantConfig,
    field.name,
    isTenantConfig,
    navigate,
    tenantId,
  ])

  return (
    <Box>
      <Editor
        placeholder={field.label}
        highlight={(code) =>
          lang === 'JSON'
            ? highlight(code, languages.js, 'js')
            : highlight(code, languages.sql, 'sql')
        }
        padding={10}
        value={value}
        onValueChange={handleChange}
        textareaClassName="codeEditorTextarea"
        style={{
          width: '100%',
          borderRadius: '4px',
          fontFamily: '"Fira code", "Fira Mono", monospace',
          border: '1px solid',
          // borderColor: error ? theme.palette.error.main : theme.palette.divider,
          fontSize: 16,
          height: 'calc(100vh - 270px)',
          overflowY: 'scroll',
          outline: 'none',
        }}
      />
      {/*
        <Box color={theme.palette.error.main} m={2} fontSize={12}>
          {error}
        </Box>
      */}
      <Button
        variant="contained"
        size="large"
        onClick={submitHandler}
        sx={{ mt: 5 }}
      >
        Save
      </Button>
      {field.type === 'JSON' && (
        <Button
          variant="contained"
          color="secondary"
          size="large"
          onClick={getValueByType}
          sx={{ mt: 5, ml: 5 }}
        >
          {`Manage ${isTenantConfig ? 'default' : 'tenant'} value`}
        </Button>
      )}
    </Box>
  )
}
