import { createSelector, createStructuredSelector } from 'reselect'
import type { RootState } from 'store'
import type { Selector } from 'reselect'
import type { TenantsState } from './reducers'

const selectAll: Selector<RootState, TenantsState> = (s) => s.tenantFeatures

export const selectEditFeature: Selector<
  RootState,
  TenantsState['editFeature']
> = createSelector(selectAll, (state) => state.editFeature)

export const selectTenants: Selector<RootState, TenantsState['tenants']> =
  createSelector(selectAll, (state) => state.tenants)

export const selectDefaultFeatures: Selector<
  RootState,
  TenantsState['defaultFeatures']
> = createSelector(selectAll, (state) => state.defaultFeatures)

export const selectError: Selector<RootState, TenantsState['error']> =
  createSelector(selectAll, (state) => state.error)

export const selectLoading: Selector<RootState, TenantsState['isLoading']> =
  createSelector(selectAll, (state) => state.isLoading)

export const selectTenantFeaturesState = createStructuredSelector<
  RootState,
  TenantsState
>({
  editFeature: selectEditFeature,
  defaultFeatures: selectDefaultFeatures,
  tenants: selectTenants,
  error: selectError,
  isLoading: selectLoading,
})
