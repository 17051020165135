import type { Action, Middleware, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import { alertsSlice } from './reducers'

export type ErrorActionPayload = PayloadAction<{ message: string }>

export const alertsMiddleware: Middleware<
  (action: Action<'specialAction'>) => number,
  RootState
> = (store) => (next) => (action) => {
  if (action.type.includes('Error')) {
    store.dispatch(
      alertsSlice.actions.popupAlert({
        message: action.payload.message,
        severity: 'error',
      }),
    )

    setTimeout(() => store.dispatch(alertsSlice.actions.removeAlert()), 3000)
  }

  return next(action)
}
