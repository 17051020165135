import {
  IconButton,
  Link,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import type { Tenant, TenantFeature } from 'types'
import { green, red } from '@mui/material/colors'

import { CONFIG_URL_KEYS } from 'features/tenantConfig/redux/hooks'
import EditIcon from '@mui/icons-material/Edit'
import { NavLink } from 'react-router-dom'
import React from 'react'
import { routes } from 'router/routes'
import styled from 'styled-components'
import { useTenantFeatures } from 'features/tenantFeatures/redux/hooks'

const $TableCell = styled(TableCell)`
  position: relative;
  min-width: 200px;
  &:hover div {
    display: block;
  }
`

const IconWrapper = styled.div`
  position: absolute;
  right: 10px;
  top: 50%;
  margin-top: -20px;
  display: none;
`

export const Row: React.FC<{
  tenant: Tenant
  onEdit: (feature: TenantFeature, tenantId: string) => void
}> = ({ tenant, onEdit }) => {
  const { config } = useTenantFeatures()
  const renderBool = (value?: boolean): React.ReactChild => (
    <Typography
      variant="subtitle2"
      sx={{ color: value ? green[500] : red[500], fontWeight: 'bold' }}
    >
      {value ? 'Enabled' : 'Disabled'}
    </Typography>
  )

  return (
    <TableRow>
      <TableCell
        scope="row"
        sx={{
          fontWeight: 'bold',
          position: 'sticky',
          left: 0,
          background: 'white',
          zIndex: 1,
          borderRight: '3px solid rgba(224,224,224,1)',
        }}
      >
        <Link
          component={NavLink}
          to={routes.tenant_configuration(
            tenant.id,
            'kpis',
            CONFIG_URL_KEYS.tenantConfigKey,
          )}
          variant="button"
        >
          {tenant.label || tenant.id}
        </Link>
      </TableCell>
      {config?.map((feature) => {
        const overridedFeature = tenant.features.find(
          (item) => item.key === feature.key,
        )
        const value = overridedFeature?.value ?? feature.value ?? ''
        return (
          <React.Fragment key={`${tenant.id}_${feature.key}`}>
            <$TableCell scope="row">
              {renderBool(overridedFeature?.is_enabled ?? feature.is_enabled)}
              {value && (
                <span>
                  Value: <b>{value}</b>
                </span>
              )}
              <div>{overridedFeature?.is_default && <i>(Default)</i>}</div>
              <IconWrapper>
                <IconButton
                  color="secondary"
                  component="span"
                  onClick={() => onEdit(overridedFeature ?? feature, tenant.id)}
                >
                  <EditIcon />
                </IconButton>
              </IconWrapper>
            </$TableCell>
          </React.Fragment>
        )
      })}
    </TableRow>
  )
}
