import { Alert, Snackbar } from '@mui/material'
import React, { FC } from 'react'
import type { AlertColor } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error'
import { red } from '@mui/material/colors'
import { selectAlerts } from 'store/alerts/selectors'
import styled from 'styled-components'
import { theme } from 'utils/theme'
import { useSelector } from 'react-redux'

const $Alert = styled(Alert)({
  color: theme.palette.background.paper,
  backgroundColor: red[300],
})

const $ErrorIcon = styled(ErrorIcon)`
  color: ${theme.palette.background.paper};
`

const $CheckCircleOutlineIcon = styled(ErrorIcon)`
  color: ${theme.palette.background.paper};
`

export const ErrorBoundary: FC = () => {
  const alerts = useSelector(selectAlerts)

  return (
    <>
      {alerts.map((item, index) => (
        <Snackbar
          open
          anchorOrigin={{
            vertical: 'top',
            horizontal: item.severity === 'success' ? 'center' : 'right',
          }}
          style={{ top: `calc(95px + ${60 * index}px)` }}
          key={item.message}
        >
          <$Alert
            severity={item.severity as AlertColor}
            iconMapping={{
              error: <$ErrorIcon />,
              success: <$CheckCircleOutlineIcon />,
            }}
          >
            {item.message}
          </$Alert>
        </Snackbar>
      ))}
    </>
  )
}
