import { call, delay, put, takeLatest } from 'redux-saga/effects'
import type { SagaIterator } from 'redux-saga'
import type { User } from './model'
import { extractErrorMessage } from 'utils/errors'
// eslint-disable-next-line import/no-cycle
import { serverAxios } from 'utils/api'
import { userSlice } from './reducers'

// Example worker + watcher !

function* fetchUsers(): SagaIterator {
  try {
    // do async call here like this
    yield delay(1000)
    const response: User[] = yield call(serverAxios.get, '/api/test')
    yield put(userSlice.actions.fetchUsersSuccess(response))
  } catch (e) {
    const error = extractErrorMessage(e as Error)
    yield put(userSlice.actions.fetchUsersError(error))
  }
}

export default [takeLatest(userSlice.actions.fetchUsers, fetchUsers)]
