import type { Maybe, TenantFeature } from 'types'
import type {
  TenantsState,
  UpdateDeafaultFeature,
  UpdateTenantFeature,
} from './reducers'
import { useAppDispatch, useAppSelector } from 'hooks/redux'
import React from 'react'

import { selectTenantFeaturesState } from './selectors'
import { tenantsSlice } from './reducers'

type UseTenantFeatures = {
  editFeature: TenantsState['editFeature']
  defaultFeatures: TenantsState['defaultFeatures']
  tenants: TenantsState['tenants']
  isLoading: boolean
  error: string
  fetchTenants: () => void
  editFeatureHandler: (feature: Maybe<TenantFeature>, tenantId?: string) => void
  updateDefaultFeature: (feature: UpdateDeafaultFeature) => void
  updateTenantFeature: (feature: UpdateTenantFeature) => void
  config: TenantFeature[]
}

export const useTenantFeatures = (): UseTenantFeatures => {
  const dispatch = useAppDispatch()
  const { defaultFeatures, tenants, editFeature, isLoading, error } =
    useAppSelector(selectTenantFeaturesState)

  const config = React.useMemo(() => {
    const defaultConfigMap = defaultFeatures.reduce((acc, item) => {
      acc[item.key] = acc[item.key] || {
        ...item,
        is_enabled: false,
      }
      return acc
    }, {} as Record<TenantFeature['key'], TenantFeature>)

    return Object.values(
      tenants.reduce((acc, item) => {
        item.features.forEach((feature) => {
          acc[feature.key] = acc[feature.key] || {
            ...feature,
            is_enabled: false,
          }
        })
        return acc
      }, defaultConfigMap),
    )
  }, [defaultFeatures, tenants])

  const fetchTenants = React.useCallback(() => {
    dispatch(tenantsSlice.actions.fetchTenants())
  }, [dispatch])

  const editFeatureHandler = React.useCallback(
    (feature: Maybe<TenantFeature>, tenantId?: string) => {
      dispatch(tenantsSlice.actions.editFeature({ feature, tenantId }))
    },
    [dispatch],
  )

  const updateDefaultFeature = React.useCallback(
    (values: UpdateDeafaultFeature) => {
      dispatch(tenantsSlice.actions.updateDefaultFeature(values))
    },
    [dispatch],
  )

  const updateTenantFeature = React.useCallback(
    (values: UpdateTenantFeature) => {
      dispatch(tenantsSlice.actions.updateTenantFeature(values))
    },
    [dispatch],
  )

  return {
    editFeature,
    defaultFeatures,
    tenants,
    isLoading,
    error,
    fetchTenants,
    editFeatureHandler,
    updateDefaultFeature,
    updateTenantFeature,
    config,
  }
}
