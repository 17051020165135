import { Paper, Table, TableBody, TableContainer } from '@mui/material'
import type { Tenant, TenantFeature } from 'types'

import { Head } from './Head'
import React from 'react'
import { Row } from './Row'

type FeaturesTableProps = {
  tenants: Tenant[]
  openToggle: (feature: TenantFeature, tenantId: string) => void
}

export const FeaturesTable: React.FC<FeaturesTableProps> = ({
  tenants,
  openToggle,
}) => (
  <TableContainer sx={{ maxHeight: 'calc(100vh - 174px)' }} component={Paper}>
    <Table stickyHeader>
      <Head />
      <TableBody>
        {tenants.map((tenant) => (
          <Row tenant={tenant} key={tenant.id} onEdit={openToggle} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)
