import { Badge, Button } from '@mui/material'

import Box from '@mui/material/Box'
import { NavLink } from 'react-router-dom'
import React from 'react'
import { environment } from 'environment'
import { routes } from 'router/routes'

export const NavMenu: React.FC = () => (
  <Box display="flex" alignItems="center">
    <Box mr={12}>
      <Badge
        badgeContent={environment.REACT_APP_CURRENT_ENV_LABEL.toLocaleUpperCase()}
        color="info"
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box pr={5}>
          <NavLink to={routes.feature_flags}>
            <img src="/logo.png" alt="Fintastic" />
          </NavLink>
        </Box>
      </Badge>
    </Box>

    <Button
      component={NavLink}
      to={routes.feature_flags}
      variant="contained"
      color="secondary"
      disableElevation
    >
      Manage features
    </Button>

    <Button
      component={NavLink}
      to={routes.default_feature_flags}
      variant="contained"
      color="info"
      disableElevation
      sx={{ ml: 2 }}
    >
      Manage default features
    </Button>
  </Box>
)
