/* eslint-disable import/no-cycle */
import type { SagaIterator } from 'redux-saga'
import { all } from 'redux-saga/effects'
import tenantConfigSagas from 'features/tenantConfig/redux/sagas'
import tenantsSagas from 'features/tenantFeatures/redux/sagas'
import usersSagas from 'features/example/redux/sagas'

export function* rootSaga(): SagaIterator {
  yield all([...usersSagas, ...tenantConfigSagas, ...tenantsSagas])
}
