/* eslint-disable import/no-cycle */
import { Box, Tab, Tabs } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import type { ConfigField } from 'types'
import { JSONField } from './JSONField'
import React from 'react'
import { SQLField } from './SQLField'
import { TenantConfigState } from 'features/tenantConfig/redux/reducers'
import { routes } from 'router/routes'

export const FORM_CONFIG: ConfigField[] = [
  {
    name: 'kpis',
    label: 'KPIs',
    type: 'JSON',
  },
  {
    name: 'bridge',
    label: 'Bridge',
    type: 'JSON',
  },
  {
    name: 'property_columns',
    label: 'Property columns',
    type: 'JSON',
  },
  {
    name: 'pnl',
    label: 'P&L',
    type: 'JSON',
  },
  {
    name: 'users',
    label: 'Users for discussions',
    type: 'JSON',
  },
  {
    name: 'account_properties_view',
    label: 'account_properties_view',
    type: 'SQL',
  },
  {
    name: 'pnl_view',
    label: 'pnl_view',
    type: 'SQL',
  },
  {
    name: 'pnl_account_view',
    label: 'pnl_account_view',
    type: 'SQL',
  },
  {
    name: 'pnl_subsidiary_view',
    label: 'pnl_subsidiary_view',
    type: 'SQL',
  },
  {
    name: 'arr_bridge_view',
    label: 'arr_bridge_view',
    type: 'SQL',
  },
]

const ConfigurationFormTab: React.FC<{
  field: ConfigField
  hidden: boolean
}> = ({ field, hidden }) =>
  !hidden ? (
    <Box mt={1} pt={1}>
      {field.type === 'SQL' && <SQLField field={field} />}
      {field.type === 'JSON' && <JSONField field={field} />}
    </Box>
  ) : null

export const ConfigurationForm: React.FC<{
  data: TenantConfigState['config']
}> = ({ data }) => {
  const navigate = useNavigate()
  const { tenantId, configKey, type } = useParams()
  const [tab, setTab] = React.useState(
    FORM_CONFIG.findIndex(({ name }) => name === configKey),
  )

  const handleTabChange = React.useCallback(
    (event: React.SyntheticEvent, newValue: number) => {
      setTab(newValue)
      navigate(
        routes.tenant_configuration(
          tenantId as string,
          FORM_CONFIG[newValue].name,
          type as string,
        ),
      )
    },
    [navigate, tenantId, type],
  )

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={tab}
          onChange={handleTabChange}
          aria-label="basic tabs example"
          scrollButtons="auto"
          variant="scrollable"
        >
          {FORM_CONFIG.map((field) => (
            <Tab label={field.label} key={field.name} />
          ))}
        </Tabs>
      </Box>
      {FORM_CONFIG.map((field, index) => (
        <ConfigurationFormTab
          hidden={tab !== index}
          field={field}
          key={field.name}
        />
      ))}
    </Box>
  )
}
