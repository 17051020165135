import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { DefaultFeatureFlagsLayout } from '../layouts/DefaultFeaturesFlagsLayout'
import { ErrorBoundary } from '../layouts/parts/ErrorBoundary'
import { FeatureFlagsLayout } from '../layouts/FeatureFlagsLayout'
import { NotFoundLayout } from '../layouts/NotFoundLayout'
import { ProtectedRoute } from 'components/common/Router/ProtectedRoute'
import { TenantConfigurationLayout } from '../layouts/TenantConfigurationLayout'
import { routes } from './routes'
import { useAuthorization } from '../features/auth/hooks/useAuthorization'
import { useTenantFeatures } from '../features/tenantFeatures/redux/hooks'
import { ModelEditLayout } from 'layouts/ModelEditLayout'

export const Router = () => {
  const { isAuthenticated, storeTokenHandler, token } = useAuthorization()
  const { fetchTenants } = useTenantFeatures()

  React.useEffect(() => {
    if (isAuthenticated) {
      storeTokenHandler()
    }
  }, [fetchTenants, isAuthenticated, storeTokenHandler])

  React.useEffect(() => {
    if (token) {
      fetchTenants()
    }
  }, [fetchTenants, token])

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={routes.feature_flags}
          element={
            <ProtectedRoute>
              <FeatureFlagsLayout />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.default_feature_flags}
          element={
            <ProtectedRoute>
              <DefaultFeatureFlagsLayout />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.tenant_configuration(':tenantId', ':configKey', ':type')}
          element={
            <ProtectedRoute>
              <TenantConfigurationLayout />
            </ProtectedRoute>
          }
        />
        <Route
          path={routes.model_edit(':tenantId')}
          element={
            <ProtectedRoute>
              <ModelEditLayout />
            </ProtectedRoute>
          }
        />
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <NotFoundLayout />
            </ProtectedRoute>
          }
        />
      </Routes>
      <ErrorBoundary />
    </BrowserRouter>
  )
}
