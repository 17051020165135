export const routes = {
  auth: {
    login: '/',
  },
  feature_flags: '/',
  default_feature_flags: '/default',
  model_edit: (tenantId: string) => `/model-edit/${tenantId}`,
  tenant_configuration: (tenantId: string, configKey: string, type: string) =>
    `/tenant-configuration/${tenantId}/${configKey}/${type}`,
}
