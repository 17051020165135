import { AppBar, ButtonGroup, IconButton, Toolbar } from '@mui/material'
import type { Maybe, Tenant } from 'types'

import Box from '@mui/material/Box'
import { Dropdown } from 'components/common/Dropdown/Dropdown'
import LogoutIcon from '@mui/icons-material/Logout'
import { NavMenu } from './NavMenu'
import React from 'react'
// import { WhiteButton } from '../WhiteButton/WhiteButton'
import styled from 'styled-components'
import { useAuth0 } from '@auth0/auth0-react'
import { useTenantFeatures } from 'features/tenantFeatures/redux/hooks'

const $Toolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`
export type HeaderProps = {
  tenant: Maybe<Tenant>
  setTenant: React.Dispatch<React.SetStateAction<Maybe<Tenant>>>
}

export const Header: React.FC<HeaderProps> = ({ tenant, setTenant }) => {
  const { logout } = useAuth0()
  const { tenants } = useTenantFeatures()
  const handleChange = React.useCallback(
    (_, value) => setTenant(value),
    [setTenant],
  )

  const handleLogout = React.useCallback(() => {
    logout({
      returnTo: window?.location.origin,
    })
  }, [logout])

  return (
    <AppBar>
      <$Toolbar>
        <Box display="flex">
          <NavMenu />
        </Box>
        <ButtonGroup variant="outlined" disableElevation>
          {/*
          <WhiteButton
            type="submit"
            variant="contained"
            sx={{ marginRight: 4 }}
            disabled
          >
            Create new tenant
          </WhiteButton>
          */}
          <Box bgcolor="white" borderRadius="4px">
            <Dropdown
              value={tenant}
              onChange={handleChange}
              placeholder="Select tenant"
              options={tenants}
            />
          </Box>
          <Box bgcolor="white" borderRadius="50%" ml={4}>
            <IconButton onClick={handleLogout}>
              <LogoutIcon />
            </IconButton>
          </Box>
        </ButtonGroup>
      </$Toolbar>
    </AppBar>
  )
}
