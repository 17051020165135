import { Grid, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import { green, red } from '@mui/material/colors'

import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import { FeatureFlagTitle } from 'components/common/FeatureFlagTitle/FeatureFlagTitle'
import type { TenantFeature } from 'types'
import TextField from '@mui/material/TextField'
import styled from 'styled-components'

const IconWrapper = styled.div`
  margin-left: 8px;
`

export const DefaultFeaturesRow: React.FC<{
  defaultFeatures: TenantFeature[]
  onEdit: (feature: TenantFeature) => void
}> = ({ defaultFeatures, onEdit }) => {
  const [newFeature, setNewFeature] = useState('')
  const renderBool = (value?: boolean): React.ReactChild => (
    <Typography
      variant="subtitle2"
      sx={{ color: value ? green[500] : red[500], fontWeight: 'bold' }}
    >
      {value ? 'Enabled' : 'Disabled'}
    </Typography>
  )

  return (
    <>
      <Grid item xs={6} display="flex" alignItems="center">
        <Typography>
          <b>Add a new feature flag</b>
        </Typography>
      </Grid>
      <Grid item xs={6} display="flex" alignItems="center">
        <TextField
          id="new-feature-name-input"
          label="New feature name"
          variant="outlined"
          value={newFeature}
          onChange={(e) => {
            if (e.target.value.includes(' ')) alert('spaces not allowed')
            else setNewFeature(e.target.value)
          }}
        />
        {newFeature && (
          <IconWrapper>
            <IconButton
              color="secondary"
              component="span"
              onClick={() =>
                onEdit({
                  is_default: false,
                  is_enabled: false,
                  key: newFeature,
                })
              }
            >
              <AddIcon />
            </IconButton>
          </IconWrapper>
        )}
      </Grid>

      {defaultFeatures?.map((feature) => (
        <React.Fragment key={feature.key}>
          <Grid item xs={6} display="flex" alignItems="center">
            <FeatureFlagTitle>{feature.key}</FeatureFlagTitle>
          </Grid>
          <Grid item xs={6} display="flex" alignItems="center">
            <div>
              {renderBool(feature.is_enabled)}
              {feature.value ?? ''}
            </div>
            <IconWrapper>
              <IconButton
                color="secondary"
                component="span"
                onClick={() => onEdit(feature)}
              >
                <EditIcon />
              </IconButton>
            </IconWrapper>
          </Grid>
        </React.Fragment>
      ))}
    </>
  )
}
