/* eslint-disable no-param-reassign */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { User } from './model'
import { createSlice } from '@reduxjs/toolkit'

export type UserState = {
  users: User[]
  isLoading: boolean
  error: string
}

const initialState: UserState = {
  users: [],
  isLoading: false,
  error: '',
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUsers(state) {
      state.isLoading = true
    },
    fetchUsersSuccess(state, action: PayloadAction<User[]>) {
      state.isLoading = false
      state.error = ''
      state.users = action.payload
    },
    fetchUsersError(state, action: PayloadAction<string>) {
      state.isLoading = false
      state.error = action.payload
    },
  },
})

export const userReducer = userSlice.reducer
