import { ConfigurationForm } from 'components/common/ConfigurationForm/ConfigurationForm'
import React from 'react'
import { useTenantConfig } from '../redux/hooks'

export const ConfigureTenantContainer: React.FC = () => {
  const {
    config,
    isTenantConfig,
    fetchTenantConfig,
    fetchDefaultConfig,
    fetchTenantSQLConfig,
    isLoading,
    isSQLField,
  } = useTenantConfig()

  React.useEffect(() => {
    if (isSQLField) {
      fetchTenantSQLConfig()
      return
    }
    const fetchConfig = isTenantConfig ? fetchTenantConfig : fetchDefaultConfig
    fetchConfig()
  }, [
    fetchDefaultConfig,
    fetchTenantConfig,
    fetchTenantSQLConfig,
    isSQLField,
    isTenantConfig,
  ])

  if (isLoading) return <div>is Loading...</div>

  return (
    <div>
      <ConfigurationForm data={config} />
    </div>
  )
}
