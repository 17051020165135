import type { Maybe, Tenant } from 'types'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { CONFIG_URL_KEYS } from 'features/tenantConfig/redux/hooks'
import { Header } from 'components/common/Header/Header'
import React from 'react'
import Typography from '@mui/material/Typography'
import { routes } from 'router/routes'
import { useTenantFeatures } from 'features/tenantFeatures/redux/hooks'

type DashboardProps = {
  title: React.ReactChild
}

export const Dashboard: React.FC<DashboardProps> = ({ children, title }) => {
  const navigate = useNavigate()
  const { tenantId } = useParams()
  const { tenants } = useTenantFeatures()

  const [tenant, setTenant] = React.useState<Maybe<Tenant>>(() =>
    tenantId
      ? tenants.find((tenantItem) => tenantItem.id === tenantId) || null
      : null,
  )

  const handleTenantChange = React.useCallback(
    (newTenant) => {
      setTenant(newTenant)
      navigate(
        routes.tenant_configuration(
          newTenant.id,
          'kpis',
          CONFIG_URL_KEYS.tenantConfigKey,
        ),
      )
    },
    [navigate],
  )

  return (
    <Box p={8} pt={16}>
      <Header tenant={tenant} setTenant={handleTenantChange} />
      <Typography variant="h1">{title}</Typography>
      {children}
    </Box>
  )
}
