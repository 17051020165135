import { Autocomplete, TextField } from '@mui/material'
import type { Maybe, Tenant } from 'types'

import React from 'react'

export type DropdownProps = {
  value: Maybe<Tenant>
  onChange: (
    event: React.SyntheticEvent<Element, Event>,
    value: Maybe<Tenant>,
  ) => void
  label?: string
  placeholder?: string
  options: Tenant[]
}

export const Dropdown: React.FC<DropdownProps> = ({
  value,
  onChange,
  label,
  placeholder,
  options,
}) => (
  <Autocomplete
    size="small"
    options={options}
    sx={{ width: 200 }}
    getOptionLabel={(option) => option.label || option.id}
    value={value}
    onChange={onChange}
    renderInput={(params) => (
      <TextField
        {...params}
        label={label}
        placeholder={placeholder}
        variant="outlined"
        InputProps={{
          ...params.InputProps,
          sx: { fontWeight: 'bold' },
        }}
      />
    )}
  />
)
